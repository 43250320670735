<template>
  <div class="personal-data">
    <Banner>
      <div class="banner-content">
        <MeepIconPersonal
          class="banner-content__icon banner-content__icon-stroke"
        />
        <span class="banner-content__text">{{ $t("data.banner") }}</span>
      </div>
    </Banner>
    <div class="personal-data-content page-layout">
      <div class="md-alignment-center-center">
        <md-card class="personal-data__card md-layout-item md-size-80">
          <PageHeader
            :title="$t('data.title')"
            :has-back="true"
            @back="backToHome"
          />
          <md-card-content v-if="isGC">
            <h3 class="personal-data__title">{{ $t("data.first-title") }}</h3>

            <p>
              Expert SA est une agence web basée à Paris. Découvrez notre agence
              sur notre site web
              <a href="https://www.expertsa.fr/" target="_blank"
                >https://www.expertsa.fr/</a
              >.
            </p>
            <p>
              Le responsable du traitement des données est la société Expert SA,
              située au 17 rue du Niger, 75012 Paris.
            </p>
            <p>
              Pour toute question relative à vos données personnelles, veuillez
              nous contacter à l’adresse
              <a href="mailto:info@expertsa.fr">info@expertsa.fr</a>.
            </p>

            <h3 class="personal-data__title">
              {{ $t("data.second-title") }}
            </h3>
            <h4 class="personal-data__sub-title">Motifs de collecte</h4>
            <p>
              Nous collectons les données strictement nécessaires au
              fonctionnement du site web
              <a href="https://groupeconseils.meep-appli.fr "
                >groupeconseils.meep-appli.fr</a
              >
              ainsi qu’à l’application associée Groupe Conseils. Les données ne
              sont pas conservées au-delà du temps nécessaire dans le cadre de
              notre collaboration.
            </p>
            <p>
              Les informations recueillies peuvent être utilisées pour :
            </p>

            <ul>
              <li>
                Vous permettre de vous connecter à votre compte;
              </li>
              <li>
                Répondre à vos demandes d’information et le cas échéant vous
                envoyer des informations complémentaires.
              </li>
            </ul>
            <p>
              Les informations recueillies par l’intermédiaire de notre site
              sont réservées à l’usage de la société Groupe Conseils. Nous nous
              engageons à respecter leur confidentialité.
            </p>

            <h4 class="personal-data__sub-title">
              Formulaire d’inscription
            </h4>
            <p>
              En créant un compte sur le site, vous nous donnez expressément
              votre consentement pour que vos données personnelles soient
              traitées en vue de vous apporter une réponse. Dans le cas d’une
              question d’ordre commerciale (demande d’information sur un produit
              ou service, demande de devis) vous pourrez recevoir des
              informations complémentaires sur nos services par email.
            </p>
            <p>Les données collectées sont les suivantes :</p>
            <ul>
              <li>
                Civilité ;
              </li>
              <li>
                Nom ;
              </li>
              <li>
                Prénom ;
              </li>
              <li>
                Société ;
              </li>
              <li>
                Site web ;
              </li>
              <li>
                Téléphone ;
              </li>
              <li>
                Adresse email ;
              </li>
              <li>
                Autres informations librement divulguées dans le champ « message
                ».
              </li>
            </ul>
            <p>
              En vous inscrivant, vous serez susceptible de recevoir des
              newsletters commerciales et d’information. Vous pourrez à tout
              moment mettre fin à l’envoi d’emails en cliquant sur le lien de
              désabonnement présent à la fin de chaque newsletter ou email.
            </p>
            <h4 class="personal-data__sub-title">
              Cookies et analyse d’audience
            </h4>
            <p>
              Le site utilise des cookies et des outils d’analyse de trafic pour
              réaliser des statistiques et améliorer la pertinence des contenus
              proposés :
            </p>
            <ul>
              <li>
                des cookies de session pour faciliter votre navigation sur le
                Site
              </li>
              <li>
                des cookies permettant de mesurer l’audience du site
              </li>
              <li>
                un outil permettant de traiter les soumissions des formulaires
                et l’envoi de messages automatisés (abonnement au blog, envoi
                d’informations en lien avec une demande déposées via le
                formulaire de contact).
              </li>
            </ul>
            <h4 class="personal-data__sub-title">
              Contenu embarqué depuis d’autres sites
            </h4>
            <p>
              Les articles de ce site peuvent inclure des contenus intégrés (par
              exemple des vidéos, images, articles…). Le contenu intégré depuis
              d’autres sites se comporte de la même manière que si le visiteur
              se rendait sur cet autre site.
            </p>
            <p>
              Ces sites web pourraient collecter des données sur vous, utiliser
              des cookies, embarquer des outils de suivis tiers, suivre vos
              interactions avec ces contenus embarqués si vous disposez d’un
              compte connecté sur leur site web.
            </p>
            <h3 class="personal-data__title">
              {{ $t("data.third-title") }}
            </h3>
            <h4 class="personal-data__sub-title">
              Les droits que vous avez sur vos données
            </h4>

            <p>
              En vertu du droit applicable, vous disposez d’un droit d’accès, de
              rectification ou d’effacement, de limitation ou d’opposition au
              traitement ainsi qu’un droit de portabilité de vos données
              personnelles.
            </p>
            <p>Vous disposez notamment du droit :</p>
            <ul>
              <li>
                d’accéder à vos données personnelles, notamment pour vérifier
                leur exactitude et leur exhaustivité ;
              </li>
              <li>de demander une rectification de vos données ;</li>
              <li>
                de vous opposer ou de demander une limitation du traitement de
                vos données personnelles ;
              </li>
              <li>
                de recevoir vos données personnelles sous un format standard et
                structuré.
              </li>
            </ul>
            <p>
              Nous mettons tout en œuvre pour que vos données personnelles
              soient protégées en tout temps, contre toute destruction
              accidentelle ou illicite, ou contre la perte accidentelle,
              l’altération, la diffusion ou l’accès non autorisés, ainsi que
              contre toute autre forme de traitement illicite.
            </p>
            <p>
              Pour exercer vos droits ou obtenir davantage d’information sur le
              traitement de vos données personnelles, veuillez nous contacter à
              l’adresse
              <a href="mailto:info@expertsa.fr">info@expertsa.fr</a>.
            </p>
          </md-card-content>

          <md-card-content v-else>
            <h3 class="personal-data__title">
              {{ $t("personal-data.who-are-we") }}
            </h3>

            <p>
              Expert SA est une agence web basée à Paris. Découvrez notre agence
              sur notre site web
              <a href="https://www.expertsa.fr/" target="_blank"
                >https://www.expertsa.fr/</a
              >.
            </p>
            <p>
              Le responsable du traitement des données est la société Expert SA,
              située au 17 rue du Niger, 75012 Paris.
            </p>
            <p>
              Pour toute question relative à vos données personnelles, veuillez
              nous contacter à l’adresse
              <a href="mailto:info@expertsa.fr">info@expertsa.fr</a>.
            </p>

            <h3 class="personal-data__title">
              {{ $t("personal-data.use-data-collected") }}
            </h3>
            <h4 class="personal-data__sub-title">Motifs de collecte</h4>
            <p>
              Nous collectons les données strictement nécessaires au
              fonctionnement du site web v2.meep-appli.fr ainsi qu’à
              l’application associée MEEP : Mon expert en poche. Les données ne
              sont pas conservées au-delà du temps nécessaire dans le cadre de
              notre collaboration.
            </p>
            <p>
              Les informations recueillies peuvent être utilisées pour :
            </p>

            <ul>
              <li>
                Vous permettre de vous connecter à votre compte MEEP;
              </li>
              <li>
                Répondre à vos demandes d’information et le cas échéant vous
                envoyer des informations complémentaires.
              </li>
            </ul>
            <p>
              Les informations recueillies par l’intermédiaire de notre site
              sont réservées à l’usage de la société Expert Sa. Nous nous
              engageons à respecter leur confidentialité.
            </p>

            <h4 class="personal-data__sub-title">
              Formulaire d’inscription
            </h4>
            <p>
              En créant un compte sur le site, vous nous donnez expressément
              votre consentement pour que vos données personnelles soient
              traitées en vue de vous apporter une réponse. Dans le cas d’une
              question d’ordre commerciale (demande d’information sur un produit
              ou service, demande de devis) vous pourrez recevoir des
              informations complémentaires sur nos services par email.
            </p>
            <p>Les données collectées sont les suivantes :</p>
            <ul>
              <li>
                Civilité ;
              </li>
              <li>
                Nom ;
              </li>
              <li>
                Prénom ;
              </li>
              <li>
                Société ;
              </li>
              <li>
                Site web ;
              </li>
              <li>
                Téléphone ;
              </li>
              <li>
                Adresse email ;
              </li>
              <li>
                Autres informations librement divulguées dans le champ « message
                ».
              </li>
            </ul>
            <p>
              En vous inscrivant, vous serez susceptible de recevoir des
              newsletters commerciales et d’information. Vous pourrez à tout
              moment mettre fin à l’envoi d’emails en cliquant sur le lien de
              désabonnement présent à la fin de chaque newsletter ou email.
            </p>
            <h4 class="personal-data__sub-title">
              Cookies et analyse d’audience
            </h4>
            <p>
              Le site utilise des cookies et des outils d’analyse de trafic pour
              réaliser des statistiques et améliorer la pertinence des contenus
              proposés :
            </p>
            <ul>
              <li>
                des cookies de session pour faciliter votre navigation sur le
                Site
              </li>
              <li>
                des cookies permettant de mesurer l’audience du site
              </li>
              <li>
                un outil permettant de traiter les soumissions des formulaires
                et l’envoi de messages automatisés (abonnement au blog, envoi
                d’informations en lien avec une demande déposées via le
                formulaire de contact).
              </li>
            </ul>
            <h4 class="personal-data__sub-title">
              Contenu embarqué depuis d’autres sites
            </h4>
            <p>
              Les articles de ce site peuvent inclure des contenus intégrés (par
              exemple des vidéos, images, articles…). Le contenu intégré depuis
              d’autres sites se comporte de la même manière que si le visiteur
              se rendait sur cet autre site.
            </p>
            <p>
              Ces sites web pourraient collecter des données sur vous, utiliser
              des cookies, embarquer des outils de suivis tiers, suivre vos
              interactions avec ces contenus embarqués si vous disposez d’un
              compte connecté sur leur site web.
            </p>
            <h3 class="personal-data__title">
              {{ $t("personal-data.use-and-transmission-data") }}
            </h3>
            <p>
              En vertu du droit applicable, vous disposez d’un droit d’accès, de
              rectification ou d’effacement, de limitation ou d’opposition au
              traitement ainsi qu’un droit de portabilité de vos données
              personnelles.
            </p>
            <p>Vous disposez notamment du droit :</p>
            <ul>
              <li>
                d’accéder à vos données personnelles, notamment pour vérifier
                leur exactitude et leur exhaustivité ;
              </li>
              <li>
                de demander une rectification de vos données ;
              </li>
              <li>
                de vous opposer ou de demander une limitation du traitement de
                vos données personnelles ;
              </li>
              <li>
                de recevoir vos données personnelles sous un format standard et
                structuré.
              </li>
            </ul>
            <p>
              Nous mettons tout en œuvre pour que vos données personnelles
              soient protégées en tout temps, contre toute destruction
              accidentelle ou illicite, ou contre la perte accidentelle,
              l’altération, la diffusion ou l’accès non autorisés, ainsi que
              contre toute autre forme de traitement illicite.
            </p>
            <p>
              Pour exercer vos droits ou obtenir davantage d’information sur le
              traitement de vos données personnelles, veuillez nous contacter à
              l’adresse
              <a href="mailto:info@expertsa.fr">info@expertsa.fr</a>.
            </p>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>
<script>
import PageHeader from "../components/PageHeader";
import { mapGetters } from "vuex";
import Banner from "@/components/Banner";
import MeepIconPersonal from "@/components/icons/MeepIconPersonal.vue";
import HasBackMixin from "@/mixins/HasBackMixin";

export default {
  name: "PersonalData",

  components: {
    PageHeader,
    Banner,
    MeepIconPersonal,
  },

  mixins: [HasBackMixin],

  computed: {
    ...mapGetters(["isGC"]),
  },

  mounted() {
    this.$scrollTo(".personal-data__card");
  },
};
</script>

<style lang="scss" scoped>
.personal-data {
  &__card {
    border-radius: 10px;
    padding: 50px 40px;

    .md-card-content {
      padding: 0;
      font-size: 17px;
    }
  }

  &__title {
    color: var(--bg-primary);
    font-size: 29px;
    margin: 25px 0;
  }
  &__sub-title {
    margin-top: 20px;
  }

  ul {
    list-style-type: none;

    li {
      text-indent: -5px;

      &::before {
        content: "-";
        margin-right: 0.5rem;
        text-indent: -5px;
      }
    }
  }
}
</style>
